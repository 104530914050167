

/**
 * General-purpose utility functions.
 */

/**
 * Swap any two given DOM nodes.
 * Originally sourced from https://stackoverflow.com/a/44562952/176247
 */
export const swapNodes = function(n1, n2) {
    var p1 = n1.parentNode;
    var p2 = n2.parentNode;
    var i1, i2;

    // May want to use isSameNode(), instead.
    if ( !p1 || !p2 || p1.isEqualNode(n2) || p2.isEqualNode(n1) ) return;

    for (var i = 0; i < p1.children.length; i++) {
        if (p1.children[i].isEqualNode(n1)) {
            i1 = i;
        }
    }
    for (var i = 0; i < p2.children.length; i++) {
        if (p2.children[i].isEqualNode(n2)) {
            i2 = i;
        }
    }

    if ( p1.isEqualNode(p2) && i1 < i2 ) {
        i2++;
    }
    p1.insertBefore(n2, p1.children[i1]);
    p2.insertBefore(n1, p2.children[i2]);
};

/**
 * Retrieve an object representation of the search (querystring) parameters found in the current 
 * page URL. Originally sourced from https://stackoverflow.com/a/2880929/176247
 */
export const retrieveUrlSearchParameters = function() {
    let urlParams = {};

    let match;
    // Regex for replacing addition symbol with a space
    let pl = /\+/g;
    let search = /([^&=]+)=?([^&]*)/g;
    let decode = function (s) {
        return decodeURIComponent(s.replace(pl, " "));
    };
    let query = window.location.search.substring(1);

    while (match = search.exec(query)) {
        if (decode(match[1]) in urlParams) {
            if (!Array.isArray(urlParams[decode(match[1])])) {
                urlParams[decode(match[1])] = [urlParams[decode(match[1])]];
            }
            urlParams[decode(match[1])].push(decode(match[2]));
        } else {
            urlParams[decode(match[1])] = decode(match[2]);
        }
    }

    return urlParams;
};

/**
 * Return a promise that will wait the specified number of milliseconds before resolving.
 * Originally sourced from https://stackoverflow.com/a/951057/176247
 */
export const sleep = function(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
};

/**
 * Pad the given source string with another padding string (multiple times, if needed) until the 
 * resulting string reaches the specified length. The padding is applied from the start of the 
 * source string. Can be replaced with the native String.padStart() when IE support is abandoned.
 * Originally sourced from https://stackoverflow.com/a/10073788/176247
 */
export const padStart = function(sourceStr, targetLength, padStr) {
    let result = sourceStr;

    sourceStr = sourceStr + '';
    padStr = padStr || '0';

    if (sourceStr.length < targetLength) {
        result = new Array(targetLength - sourceStr.length + 1).join(padStr) + sourceStr;
    }
    return result;
};

