import globalScope from './modules/global-scope.mjs';
import * as runtimeSupport from './modules/runtime-support.mjs';
import * as siteSupport from './modules/site-support.mjs';
import * as subscribeSupport from './modules/pages/subscribe-support.mjs';

import * as siteAccessLogging from 'site-access-logging';

/**
 * Divide up the scripting for the non-post pages of the app among several bundles so as to 
 * optimize the delivery of functionality to the browser client based on criticality. Part #1 
 * is currently the one and only part.
 */


/*
 * Define custom namespace to prevent pollution of the global scope.
 */
globalScope.stbNs = {};

/*
 * Populate custom namespace using an IIFE (Immediately Invoked Function Expression). More info: 
 *	https://stackoverflow.com/a/5947280/176247
 */
(function( stbNs ) {

/**
 * Bootstrap the app for non-post pages. Serves as the entry (initial load) point for all 
 * scripting.
 */
stbNs.bootstrapAppForPages = function() {

	if (!runtimeSupport.browserUnsupported) {

		if (!runtimeSupport.browserFullFeatured) {
			runtimeSupport.polyfillAcceptableBrowser();
		}
	}

	//siteSupport.updateCopyrightEndDate();

    siteAccessLogging.init({
    	//'stealthModeHostnamesArr': [],
    	'logTriggerUrl': '{{ global.logTriggerUrl }}',
      	'cloudflareToken': '{{ global.cloudflareToken }}', 
      	'forceCors': true
    });

	//siteAccessLogging.debug();

	siteAccessLogging.insertLogTrigger();
	siteAccessLogging.insertWebAnalytics();
	siteAccessLogging.displayStealthMode();

	/*
	 * The "next steps" buttons should only ever be found on the home and discuss pages. If the 
	 * referenced logic gets to be too voluminous, consider creating a dedicated "home-part-1.js" 
	 * script, as including it here forces all pages to download and parse it (unnecessarily except 
	 * for the home and discuss pages).
	 */
    const nextStepsBoxEle = document.querySelector('#next-steps-box');
    if (nextStepsBoxEle) {
    	const joinBtnEle = nextStepsBoxEle.querySelector('#next-steps-join-box button');

    	/* 
    	 * The join button's click target varies depending on which page it is being displayed.
    	 */
    	if (joinBtnEle) {
    		let clickTgt = '/discuss';
    		if (document.location.pathname !== '/') {
    			clickTgt = 'https://groups.io/g/strongtowns-baltimore/join';
    		}

			joinBtnEle.addEventListener(
				'click', function(event) {
					document.location = clickTgt;
				});
    	}

    	const subscribeBtnEle = nextStepsBoxEle.querySelector('#next-steps-subscribe-box button');
    	if (subscribeBtnEle) {
			subscribeBtnEle.addEventListener(
				'click', function(event) {
					document.location = '/subscribe';
				});
    	}
    }
	/*
	 * The subscription service form should only ever be found on the full-fledged group 
	 * subscription page. If the referenced logic gets to be too voluminous, consider creating a 
	 * dedicated "subscribe-part-1.js" script, as including it here forces all pages to download 
	 * and parse it (unnecessarily except for the subscribe page).
	 */
    const subscribeSvcFormEle = document.querySelector('#subscribe-svc-form');
    if (subscribeSvcFormEle) {
		subscribeSupport.populateSubscribeForm(subscribeSvcFormEle);

		const subscribeSubmitInputEle = subscribeSvcFormEle.querySelector('input[type="submit"]');
		subscribeSubmitInputEle.addEventListener(
			'click', subscribeSupport.handleClickSubscribeWithService);
    }
};

}(globalScope.stbNs));

/*
 * Activate as-needed to reveal elements that trigger Largest Contentful Paint events. More info: 
 *  https://web.dev/lcp/
 */
// new PerformanceObserver((entryList) => {
//   for (const entry of entryList.getEntries()) {
//     console.log('LCP candidate:', entry.startTime, entry);
//   }
// }).observe({type: 'largest-contentful-paint', buffered: true});

