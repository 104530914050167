import * as util from '../util.mjs';


/** 
 * Logic that allows for users to subscribe to the group.
 */

/**
 * Populate the input elements contained in the given full-fledged subscription form that 
 * submits to the third-party emailing service (i.e., MailChimp).
 */
export const populateSubscribeForm = function(subscribeSvcFormEle) {
	if (subscribeSvcFormEle) {
    	let emailInputEle = subscribeSvcFormEle.querySelector('[name="EMAIL"]');
    	let consentCtxInputEle = subscribeSvcFormEle.querySelector('[name="CSENT_CTX"]');
    	let consentUrlInputEle = subscribeSvcFormEle.querySelector('[name="CSENT_URL"]');
		
	    /*
	     * In the markup, the URL for the full-fledged blog subscription page has already been 
	     * set as the consent URL.
	     */
	    let consentUrl = consentUrlInputEle.value;

		/*
		 * MailChimp will error if the TLD-less "localhost" is passed as the domain in a 
		 * website/URL field value.
		 */
	    consentUrl = consentUrl.replace('http://localhost:', 'http://127.0.0.1:');
	    //console.log('consentUrl = %o', consentUrl);
	    consentUrlInputEle.value = consentUrl;

	    let nowDate = new Date();
	    // MailChimp wants dates formatted as MM/DD/YYYY
	    const currentDateStr = 
	    	util.padStart((nowDate.getMonth() + 1), 2) + '/' + 
	    	util.padStart(nowDate.getDate(), 2) + '/' + 
	    	nowDate.getFullYear();
	    const consentDateInputEle = subscribeSvcFormEle.querySelector('[name="CSENT_DATE"]');
	    consentDateInputEle.value = currentDateStr;
	}
};

/**
 * Handle the user clicking the "subscribe" button on the full-fledged subscription 
 * form that submits to the third-party emailing service (i.e., MailChimp).
 */
export const handleClickSubscribeWithService = function(event) {
	//console.log('event = %o', event);

};
