import globalScope from './global-scope.mjs';

/**
 * Logic related to the (browser) runtime environment.
 */

/**
 * Return true if the context browser client is unsupported, false otherwise. "Unsupported" is 
 * determined using feature detection, with those features based on errors seen when testing the 
 * site using Internet Explorer versions 9 and 10.
 * 
 * Tippy/Popper does not support IE v10 and older: 
 *	https://popper.js.org/docs/v2/browser-support/#ie10-and-older
 * The Fetch API polyfill does not support IE v9 and older: 
 *	https://github.com/github/fetch
 */
const calcIsBrowserUnsupported = function() {
	let result = true;

	let testsCnt = 0;
	let failedTestsArr = [];

	if ( !("Map" in globalScope) ) {
		/*
		 * Added in IE v11 (incompletely)
		 * https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Map#browser_compatibility
		 */
		failedTestsArr.push('Map');
		testsCnt++;
	}

	/*
	 * In IE v11, ("classList" in document.documentElement) is true, but 
	 * ("classList" in Element.prototype) is false. In practice, the first is enough.
	 */
	if ( !("classList" in document.documentElement) ) {
		/*
		 * Added in IE v10 (except for svg elements)
		 * https://developer.mozilla.org/en-US/docs/Web/API/Element/classList#browser_compatibility
		 */
		failedTestsArr.push('Element.classList');
		testsCnt++;
	}

	if ( !("requestAnimationFrame" in globalScope) ) {
		/*
		 * Added in IE v10
		 * https://developer.mozilla.org/en-US/docs/Web/API/window/requestAnimationFrame#browser_compatibility
		 */
		failedTestsArr.push('requestAnimationFrame');
		testsCnt++;
	}

	if ( !("MutationObserver" in globalScope) ) {
		/*
		 * Added in IE v11
		 * https://developer.mozilla.org/en-US/docs/Web/API/MutationObserver#browser_compatibility
		 */
		failedTestsArr.push('MutationObserver');
		testsCnt++;
	}

	result = (failedTestsArr.length > 0);
	if (result) {
		console.warn('Browser not supported. %i of %i total tests failed - %s', 
			failedTestsArr.length, testsCnt, failedTestsArr.join(', '));
	}

	return result;
};

/**
 * Return true if the context browser client is full-featured, false otherwise. "Full-featured" is 
 * determined using feature detection, with those features based on errors seen when testing the 
 * site using Internet Explorer v11.
 */
const calcIsBrowserFullFeatured = function() {
	let result = false;

	let testsCnt = 0;
	let failedTestsArr = [];

	if (!SVGElement.prototype.contains) {
		failedTestsArr.push('SVGElement.contains');
		testsCnt++;
	}

	if ( !(typeof Element.prototype.closest === 'function') ) {
		/*
		 * Not supported in any version of IE. 
		 * https://developer.mozilla.org/en-US/docs/Web/API/Element/closest#browser_compatibility
		 */
		failedTestsArr.push('Element.closest');
		testsCnt++;
	}

	if ( !String.prototype.startsWith ) {
		/*
		 * Not supported in any version of IE. 
		 * https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/String/startsWith#browser_compatibility
		 */
		failedTestsArr.push('String.startsWith');
		testsCnt++;
	}

	if (!NodeList.prototype.forEach) {
		/*
		 * Not supported in any version of IE. 
		 * https://developer.mozilla.org/en-US/docs/Web/API/NodeList/forEach#browser_compatibility
		 */
		failedTestsArr.push('NodeList.forEach');
		testsCnt++;
	}

	if (!globalScope.fetch) {
		/*
		 * Not supported in any version of IE. 
		 * https://developer.mozilla.org/en-US/docs/Web/API/Fetch_API#browser_compatibility
		 *
		 * The polyfill identifies itself as such (via a "polyfill = true"), but the FromData 
		 * polyfill wraps fetch and makes this property inaccessible. More info: 
		 * https://github.com/github/fetch
		 * https://github.com/jimmywarting/FormData
		 */
		failedTestsArr.push('Fetch API');
		testsCnt++;
	}

	if ( !('Promise' in globalScope) ) {
		/*
		 * Not supported in any version of IE. 
		 * https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Promise#browser_compatibility
		 */
		failedTestsArr.push('Promise');
		testsCnt++;
	}

	if ( !('assign' in Object) ) {
		/*
		 * Not supported in any version of IE. 
		 * https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Object/assign#browser_compatibility
		 */
		failedTestsArr.push('Object.assign');
		testsCnt++;
	}

	if ( !('find' in Array.prototype) ) {
		/*
		 * Not supported in any version of IE. 
		 * https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/find#browser_compatibility
		 */
		failedTestsArr.push('Array.find');
		testsCnt++;
	}

	if (typeof Blob !== 'undefined' && (typeof FormData === 'undefined' || !FormData.prototype.keys)) {
		/*
		 * FormData is only minimally implemented in IE (starting with v10).
		 * https://developer.mozilla.org/en-US/docs/Web/API/FormData#browser_compatibility
		 */
		failedTestsArr.push('FormData');
		testsCnt++;
	}

	if ( !('URLSearchParams' in globalScope) || URLSearchParams.prototype.polyfill === true) {
		/*
		 * Not supported in any version of IE. 
		 * https://developer.mozilla.org/en-US/docs/Web/API/URLSearchParams/URLSearchParams#browser_compatibility
		 *
		 * The polyfill identifies itself as such. More info: 
		 * https://github.com/jerrybendy/url-search-params-polyfill
		 */
		failedTestsArr.push('URLSearchParams');
		testsCnt++;
	}

	result = (failedTestsArr.length === 0);
	if (!result) {
		console.warn('Browser not full-featured. %i of %i total tests failed - %s', 
			failedTestsArr.length, testsCnt, failedTestsArr.join(', '));
	}

	return result;
};

/**
 * Add simple/lite polyfills for browsers that are in the grey zone between unsupported and 
 * full-featured.
 */
export const polyfillAcceptableBrowser = function() {
	/**
	 * Polyfill the DOM method Element.closest. Not supported in any version of IE. 
	 * https://developer.mozilla.org/en-US/docs/Web/API/Element/closest#browser_compatibility
	 *
	 * Originally sourced from https://github.com/jonathantneal/closest
	 * Another version is available at 
	 * https://developer.mozilla.org/en-US/docs/Web/API/Element/closest#Polyfill
	 */
	const ElementPrototype = window.Element.prototype;

	if (typeof ElementPrototype.matches !== 'function') {
	    ElementPrototype.matches = 
	        ElementPrototype.msMatchesSelector || 
	        ElementPrototype.mozMatchesSelector || 
	        ElementPrototype.webkitMatchesSelector || 
	        function matches(selector) {
	            let element = this;
	            const elements = (element.document || element.ownerDocument).querySelectorAll(selector);
	            let index = 0;

	            while (elements[index] && elements[index] !== element) {
	                ++index;
	            }

	            return Boolean(elements[index]);
	        };
	}

	if (typeof ElementPrototype.closest !== 'function') {
	    ElementPrototype.closest = function closest(selector) {
	        let element = this;

	        while (element && element.nodeType === 1) {
	            if (element.matches(selector)) {
	                return element;
	            }

	            element = element.parentNode;
	        }

	        return null;
	    };
	}

	/**
	 * Polyfill String.startsWith. Not supported in any version of IE. 
	 * https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/String/startsWith#browser_compatibility
	 *
	 * Originally sourced from 
	 * https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/String/startsWith#Polyfill
	 */
	if (!String.prototype.startsWith) {
	    Object.defineProperty(String.prototype, 'startsWith', {
	        value: function(search, rawPos) {
	            var pos = rawPos > 0 ? rawPos|0 : 0;
	            return this.substring(pos, pos + search.length) === search;
	        }
	    });
	}

	/*
	 * Polyfill NodeList.forEach. Not supported in any version of IE. 
	 * https://developer.mozilla.org/en-US/docs/Web/API/NodeList/forEach#browser_compatibility
	 *
	 * Originally sourced from https://developer.mozilla.org/en-US/docs/Web/API/NodeList/forEach#polyfill
	 */
	if (window.NodeList && !NodeList.prototype.forEach) {
	    NodeList.prototype.forEach = Array.prototype.forEach;
	}

	/*
	 * Polyfill the contains() method on needy elements. Only partially supported in IE.
	 * https://developer.mozilla.org/en-US/docs/Web/API/Node/contains#browser_compatibility
	 */
	if (!SVGElement.prototype.contains) {
	    SVGElement.prototype.contains = HTMLDivElement.prototype.contains;
	}
};

export const browserUnsupported = calcIsBrowserUnsupported();
export const browserFullFeatured = calcIsBrowserFullFeatured();

// window.onerror = function(message, url, line, col, error) {
// 	console.error(message + '\n at ' + line + ':' + col + ' of ' + url);
// };
